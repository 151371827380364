import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/hotels-list/:id',
    name: 'hotel_edit',
    component: () => import('../components/Hotel_edit.vue')
  },
  {
    path: '/hotels-list',
    name: 'hotels',
    component: () => import('../components/Hotels.vue')
  },
  {
    path: '/trains',
    name: 'trains',
    component: () => import('../components/Trains.vue')
  },
  {
    path: '/trains/:id',
    name: 'trains_edit',
    component: () => import('../components/Trains_edit.vue')
  },
  // {
  //   path: '/bus',
  //   name: 'bus',
  //   component: () => import('../components/Bus.vue')
  // },
  // {
  //   path: '/bus/:id',
  //   name: 'bus_edit',
  //   component: () => import('../components/Bus_edit.vue')
  // },
  {
    path: '/consulting',
    name: 'consulting',
    component: () => import('../components/Consulting.vue')
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('../components/Reserve.vue')
  },
  {
    path: '/flights',
    name: 'flights',
    component: () => import('../components/Flights.vue')
  },
  {
    path: '/flights/:id',
    name: 'flight_id',
    component: () => import('../components/Flight_edit.vue')
  },
  {
    path: '/visa',
    name: 'visa',
    component: () => import('../components/Visa.vue')
  },
  {
    path: '/hotel_new',
    name: 'hotel_new',
    component: () => import('../components/Hotel_new.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../components/Settings.vue')
  },
  {
    path: '/tours/:id',
    name: 'tours_edit',
    component: () => import('../components/Tour_edit.vue')
  },
  {
    path: '/tours',
    name: 'tours',
    component: () => import('../components/Tours.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: "blank"},
    component: () => import('../components/Login.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../components/Notifications.vue')
  }
]




const router = new VueRouter({
  routes,
  mode: 'history'
})


export default router
